import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import CTA1 from "../components/CTA1";
import IWChero from "../components/hero/IWChero";

function CWII() {
  return (
    <Layout>
      <IWChero />
      <Container>
        <div id="tabs-container">
          <div id="sidebar">
            <ul>
              <li>
                <Link href="#New Construction Inspection">
                  New Construction Inspections
                </Link>
              </li>
              <li>
                <Link href="#Pre-Sale Inspection">Warranty Inspections</Link>
              </li>
              <li>
                <Link href="#Home Maintenance Inspection">
                  Insurance Inspections
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>
            Insurance Inspections, Warranty Inspections & New Construction
            Inspections
          </h3>
          <p>
            Young Home Inspections has been providing quality construction
            inspections, warranty inspections, and insurance inspections for new
            houses for over 30 years. We provide superior quality inspections,
            that you can count on. We are a rock-solid time-tested inspection
            team. Trust that our experts will provide you with the highest
            quality reports, as well as, the experience to know what to look
            for. We want to give you peace of mind, that your mortgage is paying
            on quality.
          </p>
          <h3>
            Why you need a new construction home inspection, before you install
            Drywall.
          </h3>
          <p>
            Most reputable builders are honest, hard-working professionals, who
            try to do their due-diligence is to deliver a well-valued product,
            that meets all local building codes, in a reasonable time. Almost
            all builders want to leave their mark as a quality home builder.
            However, in almost all cases, the builder is not the one doing the
            actual construction work on the home.
          </p>
          <p>
            A typical builder will use 10-20 subcontractors on a single new
            house, depending on the scope of work and the size of your home. If
            you include specialists that install security systems, entertainment
            systems, pools, etc., that number can increase. The sheer volume of
            participants in the building process can lead to a miscommunication
            or even a lack of communication.{" "}
          </p>
          <p>
            A lot of times subcontractors working on one portion of the house
            construction, unintentionally harm another portion being worked.
            Almost every builder relies on their sub-contractors and far too
            often the sub-contractor who gets the job was the low bidder.
          </p>
          <p>
            So the reality is{" "}
            <strong>Your house is being built by the lowest bidder.</strong>
          </p>
          <p>
            After performing a private home inspection on thousands of new
            homes, we have compiled a list of the 20 most common problems or
            flaws (even cosmetic flaws), found in new construction homes. A lot
            of times the issues were so subtle, even the builder wasn't aware.
          </p>
          <p>
            However, since we were able to identify the issues before the
            construction workers left the jobsite for good, they were corrected
            before the owners of the new house moved in.
          </p>
          <ol>
            <li>
              Insulation that is missing in unseen portions of attic and other
              areas
            </li>
            <li>Electrical outlets that have been Incorrectly wired</li>
            <li>Air ducts in attic that have been torn or crushed</li>
            <li>
              Roof straps for roof to wall attachments that have been
              incorrectly installed or missing completely
            </li>
            <li>Roof tiles that have slipped or cracked</li>
            <li>Improper venting of the home</li>
            <li>
              Dryer vent that has been improperly terminated or uses improper
              material
            </li>
            <li>
              Windows that have been impropery installed or have broken locks or
              springs
            </li>
            <li>
              The slab extends beyond the exterior wall creating, slab lippage.
              This will typically lead to moisture intrusion into the home
            </li>
            <li>Safety function of the garage door, not working properly</li>
            <li>Metal plates that are missing below the trusses</li>
            <li>
              Bolts that secure the bottom plate to the ground have been
              improperly tightened
            </li>
            <li>
              Fire retardent foam that is missing at penetrations along the top
              plates
            </li>
            <li>Electrical wires that are missing nail plates</li>
            <li>
              Trusses that have been drilled through or cut by subcontractors.
              This compromises structural integrity
            </li>
            <li>
              Masonry cells that have been improperly filled in portion of
              building
            </li>
            <li>Fascia and soffit that have been improperly installed</li>
            <li>
              Window wraps or Tyvek that has been installed improperly or is
              missing
            </li>
            <li>
              Exterior swinging and sliding doors that have been improperly
              installed
            </li>
            <li>
              Sub-floors that squeak, typically the result of improper
              installation (e.g., loose screws)
            </li>
          </ol>
          <p>
            Our team wants to make sure hidden building defects or attempted
            shortcuts are caught and corrected before you get the final bill.
            This way, when you move in all you need to worry about is where to
            put the furniture and appliances. The last thing you want is
            headaches.
          </p>
        </div>
        <div>
          <h3 id="New Construction Inspection">
            New Construction Phase Inspection
          </h3>
          <p>
            A new construction inspection, also known as a construction or phase
            inspection, is an invaluable service for anyone who is looking to
            have a reliable and nonbiased professional opinion regarding the
            quality and installation practices being utilized in their new home
            during the building process. The new home construction process can
            be an unexpectedly overwhelming process for anyone. Having a
            professional building inspector on call to help guide you through
            the home building process will absolutely bring value to your future
            purchase.
          </p>
          <h4>Phase Inspection Process</h4>
          <p>
            There are three opportunities available during the building cycle
            that are recommended to have your third-party inspector evaluate and
            report on the workmanship and construction of your new home. The
            standard 3 phase inspection process is utilized to ensure that major
            construction defects within your home are not covered up.
          </p>
          <p>
            There is a market for these types of inspections for a reason.
            Additionally, most high-quality home builders fully welcome a
            third-party inspection. The new construction phase inspection is
            conducted at three separate times:
          </p>
          <ol>
            <li>
              The first inspection should be conducted prior to your concrete
              being poured (Pre-pour Inspection)
            </li>
            <li>
              The second inspection takes place prior to the interior walls and
              insulation being installed (Pre-Drywall Inspection)
            </li>
            <li>
              The final inspection takes place after the home is complete,
              usually around the time of your initial “Final Walkthrough
              Inspection”.
            </li>
          </ol>
          <hr />
          <Row>
            <Link to="/request" className="btn btn-secondary mb-50">
              <p>Schedule Your Inspection Now</p>
            </Link>
          </Row>
        </div>
        <div>
          <h3>Pre-pour or Footer Foundation Inspection</h3>

          <h4>Footer (Pre-pour) Inspections</h4>
          <p>
            There is ample value in having your home’s foundation inspected
            prior to the pour day. If you are considering having your foundation
            inspected there are a few critical points you should plan for.
            Failure to install your home’s foundation in accordance with the
            engineered plans can have a devastating impact on the integrity of
            your home’s foundation.
          </p>
          <p>
            Furthermore, there are numerous workmanship defects that are present
            on nearly every foundation that can impact the visible appearance as
            well as the bearing capacity of the foundation. Once the concrete is
            poured, the opportunity to have your water lines, gas lines and
            foundation inspected is gone.
          </p>
        </div>
      </Container>
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-2">
            <StaticImage src="../images/slab.jpg" />
          </Col>
          <Col lg={6} className="fifty-split-1">
            <h4>When to Schedule the Foundation Inspection</h4>
            <p>
              It takes time to coordinate with concrete companies for the
              required concrete for your foundation. That means, your builder
              knows a few days in advance when they plan on pouring. The best
              time for the pre-pour inspection is typically two days prior to
              the actual pour day. This will help ensure that the foundation is
              ready to inspect, as well as give the builder sufficient time to
              make any and all needed corrections, or postpone the pour day.
              Additionally, this will allow you some time to re-inspect the
              repairs prior to pour day.
            </p>
            <p>
              Another way to get an idea on the intended pour day is to ask the
              builder when their in-house inspector is scheduled to review the
              foundation. If we know when the builder’s inspector will arrive to
              perform the code inspection, we can usually schedule the
              inspection around that date, as the forms and reinforcements are
              typically complete at that time.
            </p>
            <p>
              Upon completion of the on-site inspection, Young Home Inspections
              will return to the office and begin constructing your digital
              report. The report will be comprised of digital photographs and
              locations of each defect, as well as the relevant references for
              issues discovered during the inspection.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h4>Framing Inspection</h4>
            <p>
              There is ample value in having your home’s foundation inspected
              prior to the pour day. If you are considering having your
              foundation inspected there are a few critical points you should
              plan for. Failure to install your home’s foundation in accordance
              with the engineered plans can have a devastating impact on the
              integrity of your home’s foundation.
            </p>
            <p>
              Furthermore, there are numerous workmanship defects that are
              present on nearly every foundation that can impact the visible
              appearance as well as the bearing capacity of the foundation. Once
              the concrete is poured, the opportunity to have your water lines,
              gas lines and foundation inspected is gone.
            </p>

            <h4>When to Schedule the Framing Inspection</h4>
            <p>
              The best time to schedule the framing inspection will typically
              coincide with your builder’s framing walk-through. We like to do
              this inspection right before the builder has scheduled their own
              inspection with city inspectors or local code inspectors. A few
              days to a week ahead of their own inspection will give them time
              for them to fix any issues we find before they do their own
              inspection. Then when you do your walk through with your builder
              you will either have our written report with photos of any
              deficiencies we found as your check list for the necessary
              repairs, or you add a re-inspect inspection and hire us to come
              back and re-inspect the work ourselves.
            </p>
            <p>
              This also happens to be in-line with the building officials
              required inspection.
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <StaticImage src="../images/framing-inspection.jpg" />
          </Col>
        </Row>
        <div>
          <h3>3 Common Framing Installation Problems</h3>
          <ol>
            <li>
              The most common issue found during the framing inspection by every
              builder is improper notching and boring of the interior load
              bearing stud walls. After the framers have completed their
              installation, the electrical and plumbing are installed through
              the home’s interior walls. During this process, over-zealous
              cutting of load bearing studs is conducted in order to make way
              for the utilities. There are requirements that must be met in
              order to maintain the integrity of load-bearing walls after they
              have been damaged. This, of course, goes unseen very often.
            </li>
            <li>
              Another common problem is with the installation and flashing of
              the home’s windows and exterior penetrations. Poor flashing
              installation will in most cases lead to water damage to the home.
              Unfortunately, this can take quite some time to finally manifest
              itself within the home once it has been covered. This is one of
              the primary reasons for an inspection at this stage.
            </li>
            <li>
              Installation defects in regard to the home’s water-resistive
              barrier and air barrier are also quite common. The water-resistive
              barrier and air barrier are installed in order to prevent the
              infiltration of water and moisture laden air into the home. When
              not installed properly, which is often the case, an unnecessary
              amount of unconditioned air can enter the home’s thermal envelope.
            </li>
          </ol>
          <h3>Final Inspection</h3>
          <p>
            The final inspection should be scheduled a few days before your
            scheduled final walk-through with your builder. This will ensure
            that the building process is near completion so systems can be
            inspected. It also is your safety net before you sign off on your
            closing to make sure your home is in move in condition. This
            inspection incorporates all the major systems in the home and is the
            most time consuming of the 3 phases.
          </p>
          <p>
            We truly appreciate you taking the time to consider Young Home
            Inspections as your home inspector, and we look forward to assisting
            you with your new home inspection needs.
          </p>
        </div>
        <Row>
          <h3>Things You Can Do When Hiring Your Personal Inspector</h3>
          <p>
            New construction phase inspections can be tricky to schedule. If
            your project manager is off by a few days on his timeline, or
            last-minute paperwork needs to be submitted, your scheduled
            inspection could be missed. That is why it is important to let your
            home building representatives know in advance that you wish to hire
            an independent building inspector to perform phase inspections
            throughout the building process. Below is a list of actions that you
            can take to ensure that your construction inspection goes off
            without any difficulties.
          </p>
          <ol>
            <li>
              Try and let your builder or project manager know well in advance
              that you will have a third-party inspector conducting independent
              phase inspections on your behalf. This has the advantage of
              putting them on notice, which will improve their attention to
              detail from the start. Also, it will require your project manager
              to be more accurate with their projected timelines. One of the
              most common complaints when dealing with your project manager is
              their inability to deliver an accurate timeline.
            </li>
            <li>
              Your home builder may also have some requirements that may need to
              be met by your inspector. Try and obtain this well in advance and
              get it to your inspector to avoid any last-minute difficulties.
              The requirements are usually minimal, and any quality inspection
              company should have no issues supplying the needed documentation.
            </li>
          </ol>
        </Row>
        <Row>
          <h3 id="Pre-Sale Inspection">11-Month Warranty Inspection</h3>
          <p>
            Do you have a home that is still under warranty? We have a warranty
            inspection process that allows us to provide you with a detailed
            list of issues that need to be addressed prior to the warranty
            expiration.
          </p>
          <p>
            If you do this as recommended, in the month 11, your warranty is
            about to expire. In most cases, warranties typically offer coverage
            on workmanship and materials as it relates to the many components of
            the home, like the roof, windows, ventilation, HVAC, plumbing and
            electrical systems. We highly recommend you take advantage of this
            final opportunity before your warranty expires.
          </p>
          <p>
            We do have a comprehensive inspection that covers all major
            components of your building. This provides you an opportunity to
            have a State licensed inspector prepare a third party report you can
            give directly to your builder. This virtually eliminates any going
            back and forth with your builder about what constitutes a deficiency
            within the home, because that is what we are licensed to do and they
            know this.
          </p>
          <p>
            Remember this inspection can be done in any month during your first
            year, prior to your warranty running out. Schedule this while you
            are available during that time. The last thing you want to do is
            wait until it is too late.
          </p>
        </Row>
        <Row>
          <Link to="/request" className="btn btn-secondary mb-50">
            <p>Schedule Your Inspection Now</p>
          </Link>
        </Row>
      </Container>
      <Container>
        <Row>
          <h3 id="Home Maintenance Inspection">Insurance Inspections</h3>
        </Row>
        <Row>
          <h3>Roof Certification Inspections</h3>
          <p>
            Many homeowner’s insurance companies are now requiring Roof
            Condition Certification Inspections on homes that are a certain age.
            The age of requirement is determined by several factors including
            which company you’re insured with, the type of roof, and where you
            are located.
          </p>
          <p>
            The request from the insurance company for a Roof Certification
            Inspection will usually come in the mail several months prior to the
            policy renewal date or before issuing a new policy altogether. Proof
            of a roof replacement is also acceptable in lieu of an inspection in
            most cases. Failure to provide a Roof Certification Inspection or
            proof of roof replacement usually results in a denial.
          </p>
          <p>
            A Roof Certification Inspection is not a pass or fail situation but
            just a report on the present condition of your roof and how much
            estimated life there is left in the covering. A roof cannot leak and
            has to have three viable years of use remaining. We inspect the
            following when conducting a Roof Certification Inspection:
          </p>
          <ul className="mb-30">
            <li>Roof covering type</li>
            <li>Age of roof covering</li>
            <li>Date of last update</li>
            <li>Type of last update</li>
            <li>Visible damage</li>
            <li>Granuale loss</li>
            <li>Cracking</li>
            <li>Signs of leaks</li>
            <li>Curling</li>
            <li>Blistering</li>
            <li>Exposed nails heads</li>
            <li>Chipped or broken tiles</li>
            <li>Rust or any visible damage from acts of nature, trees, etc.</li>
          </ul>
          <p>
            Please keep in mind that a Roof Certification Inspection is
            different from a Wind Mitigation Inspection. A Wind Mitigation
            Inspection is focused on the windstorm minimizing properties of your
            home and not just the condition of the roof itself. A Wind
            Mitigation Inspection also can help lower your homeowner’s insurance
            cost, which is why we highly recommend scheduling both if you have
            been required to obtain a Roof Certification Inspection from your
            insurer.
          </p>
          <p>
            If you have any questions about a Roof Certification Inspection
            request you have received or would like to schedule an inspection
            feel free to contact us at any time. We’re always here to help!
          </p>
          <Link to="/request" className="btn btn-secondary  mb-50">
            <p>Schedule Your Inspection Now</p>
          </Link>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h3>Wind Mitigation Inspections</h3>
            <p>
              One of the most common question we are asked is about Wind
              Mitigation Inspections. Most people have heard Wind Mitigation
              Inspection can potentially save you money on your homeowner’s
              insurance policy rates. Do you even know if that is true? We are
              happy to tell you that is, in fact, true!
            </p>

            <p>
              As a matter of fact, this is actually a State law. Florida Statue
              627.0629 states that “Credits, discounts, or other rate
              differentials, or appropriate reductions in deductibles, for
              fixtures and construction techniques that meet the minimum
              requirements of the Florida Building Code must be included in the
              rate filing…” This means that if your home has undergone the
              proper construction techniques to mitigate (or minimize) wind
              damage, your homeowner’s insurance company must provide you with
              certain discounts.
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <StaticImage src="../images/insurance.jpg" />
          </Col>
        </Row>
        <Row>
          <p>
            This also means that with a Wind Mitigation Inspection your rates
            have the potential for a substantial reduction when conducted by a
            Home Inspector, Building Contractor, or another a licensed and
            qualified professional such as a Structural Engineer or Architect.
            Keep in mind that prices vary and if the price seems too good to be
            true, it probably is. That’s why it’s a good idea to always ask. For
            verification of licenses and certifications when dealing with anyone
            who claims to be a professional.
          </p>
          <p>
            While Wind Mitigation rates vary a great deal and depending on which
            insurer you have and where your home is located, just the windstorm
            coverage can be as much as 60% or more of your premium. A reduction
            in your rate for proper Wind Mitigation adherence is only applied to
            the actual windstorm portion of your policy’s rate.
          </p>
          <p>
            This inspection is for the concerned home owner that wants to know
            the present condition of their home and it’s components. If there
            are any hazards or deficiencies that need immediate attention? They
            also want to know what is in need of repair now what to budget for
            in the future.
          </p>
        </Row>
        <Row>
          <Col>
            <p>
              We conduct a comprehensive Wind Mitigation Inspection that
              includes the following checklist and more:
            </p>
            <ul>
              <li>Building Code based on date home was constructed</li>
              <li>Roof installation date - Date permit was pulled</li>
              <li>Roof deck attachment</li>
              <li>Roof to wall attachment</li>
              <li>Roof geometry</li>
              <li>Secondary water resistance barrier compliance</li>
              <li>Opening protection compliance</li>
            </ul>
          </Col>
          <p>
            It is important to remember that credits are individual and that
            it’s not necessary to meet every requirement to obtain a discount on
            your homeowner’s insurance rate.
          </p>
          <p>
            If you are ready to schedule your Wind Mitigation Inspection or
            would like to talk your specific situation and find out if a Wind
            Mitigation Inspection is right for you don’t hesitate to contact us.
            We would to hear from you and we’re always here to help!
          </p>
          <Link to="/request" className="btn btn-secondary  mb-50">
            <p>Schedule Your Inspection Now</p>
          </Link>
        </Row>
        <Row>
          <Col>
            <h3>4-point Inspection</h3>
            <p>
              In the state of Florida, if you’re wanting to purchase an old home
              or even just an "older" home from a seller, there’s a pretty big
              chance that you will be asked for a 4-point inspection before
              being able to get a homeowner’s insurance policy.
            </p>
            <p>
              4 Points are typically required on homes 30 years old or older.
              Also any sinkhole remediated home or Bank owned homes. Some
              insurance carriers require them at less than 30 years it can vary
              by carrier.
            </p>
            <p>
              At this point you’re probably wondering what a 4-point inspection
              actually entails. 4 Point Home Inspections focus on 4 major
              systems in a home. These 4 systems are HVAC (heating and air
              conditioning), electrical, plumbing, and the roof. A 4 point
              inspection can only be performed by a building contractor or more
              frequently a licensed inspector.
            </p>
            <p>
              While often times you can provide an insurance company a regular
              home inspection instead of a 4 point inspection, a lot of times it
              is required by certain insurance companies.
            </p>
            <p>
              4 point inspections are good for 3 years, so if you’re thinking
              about switching insurance companies, you may not need one if you
              recently purchased your home or had it inspected.
            </p>
            <p>
              There are a lot of reasons why you may declined for coverage due
              to a 4 point inspection with a particular insurance company. There
              are some insurance companies that will still extend you coverage
              even if you “fail” certain aspects of a 4 point inspection. They
              will simply exclude coverage for whatever systems they deem to be
              issues currently. Many times, this gives the homeowner the chance
              to fix any outstanding problems for the whole home to eligible for
              insurance again.
            </p>
            <p>
              If you interested in purchasing an older home it is always a good
              idea to get a 4 point home inspection before even making an offer.
              This will give a good starting point for the value of the home as
              well as help you make an educated estimation about repair cost
              that may be needed. It is also beneficial to obtain a 4 point
              inspection to know what your insurance costs are likely to be
              before making a purchase only to find out you won’t be able to
              insure the home you recently purchased.
            </p>
            <p>
              At Young Home Inspections, we give you a secure and stress-free
              buying experience right from the start. Knowing what’s going on
              “behind the scenes” in real estate transaction gives you the upper
              hand for negotiation and bargaining. You don’t want to walk into
              to any home sale blindly.
            </p>
          </Col>
        </Row>
      </Container>
      <CTA1 />
    </Layout>
  );
}

export default CWII;
