import React from "react";
import { Container } from "react-bootstrap";

const IWChero = () => {
  return (
    <div className="overlay-hero banner banner-page banner-residential mb-30">
      <Container>
        <div className="mb-30">
          <h2 className="banner-head mb-30">
            Insurance, Warranty, & New Construction Inspections
          </h2>
        </div>
      </Container>
    </div>
  );
};

export default IWChero;
